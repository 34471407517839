<template>
  <div>
    <h1>当前版本v2.0.3</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v2.0.3
        </h3>
        <p>2022年8月11日</p>
        <p>CI-CD自动部署</p>
      </div>
      <div>
        <h3>
          v2.0.2
        </h3>
        <p>2022年7月22日</p>
        <p>样式细节调整</p>
      </div>
      <div>
        <h3>
          v2.0.1
        </h3>
        <p>2022年7月5日</p>
        <p>按钮新增loading</p>
      </div>
      <div>
        <h3>
          v2.0.0
        </h3>
        <p>2022年7月1日</p>
        <p>初始版本上线</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
